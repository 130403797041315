<template>
    <div id="reg">
        <Transition name="fade-transition">

            <v-stepper v-model="step" elevation="4" class="white" dark>
                <v-stepper-header class="secondary">
                    <v-stepper-step :complete="step > 1" color="success"
                                    step="1">
                        Begin
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 2" color="success"
                                    step="2">
                        Details
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3" :complete="step > 3" color="success">
                        Apps
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="4" :complete="step > 4" color="success">
                        Security
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="5" :complete="step > 5" color="success">
                        CJIS
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="6" :complete="step > 6" color="success">
                        OTP
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="7" :complete="step > 7" color="success">
                        Finish
                    </v-stepper-step>

                </v-stepper-header>

                <!--Pre-fetch badge in the event of a direct load in here, but do not display-->
                <v-img class="mx-auto" style="display:none;"
                       max-height="225"
                       max-width="225" contain
                       src="../images/blackwhite_badge_supermin4.png" eager
                       transition="fade-transition">

                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0"
                               align="center"
                               justify="center">
                            <v-progress-circular indeterminate
                                                 color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>

                </v-img>

                <v-stepper-items>

                    <!--Begin Step-->
                    <v-stepper-content step="1" class="scroll_card">

                        <v-card class="mb-12" light flat height="auto">
                            <div>
                                Secure Registration can take up to 10 minutes depending on how long email notifications take to reach you. Please ensure you have good connectivity and a few moments to complete this process, as partial registrations will be discarded for security reasons.
                                <br /><br />The arrows can be used to navigate between the registration steps. You can cancel the registration at any time using the "<strong>Cancel</strong>" button and return to the login page.

                                <br /><br /><strong> Note: </strong> If you still require CJIS security awareness training, please contact RPD records at <a href="mailto:idsup@reno.gov">idsup@reno.gov</a> . You MAY NOT continue with the registration until you've completed the training.

                            </div>
                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">

                            <v-col md="1" class="text-center">
                                <v-btn light disabled color="primary" @click="step = 1" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_reg" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 2" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowRight
                                    </v-icon>
                                </v-btn>
                            </v-col>




                        </v-row>




                    </v-stepper-content>

                    <!--Details Step-->
                    <v-stepper-content step="2" class="scroll_card">

                        <v-card class="mb-12" light flat
                                height="auto">
                            <div class="mb-2">
                                Please fill out the following *required personal information.
                            </div>

                            <v-form ref="detail_step_form" v-model="detail_step_form_valid" class="mt-4">
                                <v-text-field label="*Email" clearable maxlength="100"
                                              placeholder="JohnDoe@reno.gov"
                                              required
                                              v-model="reg_form.email"
                                              :rules="[field_rules.required, field_rules.email]">
                                </v-text-field>
                                <v-text-field label="*First Name" clearable maxlength="60"
                                              placeholder="John"
                                              required
                                              v-model="reg_form.fname"
                                              :rules="[() => !!reg_form.fname || 'This field is required']">
                                </v-text-field>
                                <v-text-field label="*Last Name" clearable maxlength="60"
                                              placeholder="Doe"
                                              required
                                              v-model="reg_form.lname"
                                              :rules="[() => !!reg_form.lname || 'This field is required']">
                                </v-text-field>
                                <v-text-field label="*Badge/Employee #" clearable maxlength="10"
                                              placeholder="12345"
                                              required
                                              v-model="reg_form.badge"
                                              :rules="[() => !!reg_form.badge || 'This field is required']">
                                </v-text-field>
                            </v-form>

                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 1" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_reg" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="VerifyStep2" :disabled="!detail_step_form_valid" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowRight
                                    </v-icon>
                                </v-btn>
                            </v-col>


                        </v-row>






                    </v-stepper-content>

                    <!--Apps Step-->
                    <v-stepper-content step="3" class="scroll_card">

                        <v-card class="mb-12" light flat
                                height="auto">
                            <div class="mb-2">
                                Please indicate the App(s) you are requesting by using the drop down list to select the apps. The critical apps are pre-selected.
                            </div>

                            <v-select class="app-select mt-4"
                                      v-model="selected_apps" clearable outlined solo :items="app_list"
                                      item-text="appname" label="Select Apps" multiple color="primary"
                                      :menu-props="{ offsetY: true }" item-value="appname">


                                <template v-slot:selection="{ item, index }">
                                    <v-chip v-if="index < selected_apps_preview_count" close @click:close="deleteChip(item, selected_apps)" color="primary">
                                        <span>{{ item }}</span>
                                    </v-chip>
                                    <span v-if="index === selected_apps_preview_count"
                                          class="grey--text text-caption">
                                        (+{{ selected_apps.length - selected_apps_preview_count }} other apps)
                                    </span>
                                </template>


                            </v-select>

                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 2" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_reg" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="VerifyStep3" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowRight
                                    </v-icon>
                                </v-btn>
                            </v-col>


                        </v-row>




                    </v-stepper-content>

                    <!--Security Step-->
                    <v-stepper-content step="4" class="scroll_card">

                        <v-card class="mb-12" light flat id="attachhere"
                                height="auto">
                            <div class="mb-2">
                                Please create two *required security questions below. You may <strong>free-type your own custom security questions</strong> into the fields or <strong>select one</strong> from a recommend list.
                                <br /> <br />
                                <strong>Note</strong> : If you choose to create your own custom security question, it should <strong>NOT</strong> be easily guessed or researched online, it should <strong>NOT</strong> change over time, and it <strong>SHOULD</strong> be memorable and simple.
                            </div>

                            <v-form ref="security_step_form" v-model="security_step_form_valid" class="mt-4" id="tesa">
                                <v-combobox label="*Security Question 1" :items="sec_questions" allow-overflow clearable
                                            placeholder="What is your favorite sports team?"
                                            required maxlength="200"
                                            v-model="reg_form.question1"
                                            :rules="[() => !!reg_form.question1 || 'This field is required']">
                                    <template v-slot:item="{ index, item }">

                                        <div class="pa-1 mb-1" style="border-bottom: 0.5px solid #c1c1c1; width: 100%;">
                                            {{item}}
                                        </div>


                                    </template>
                                </v-combobox>
                                <v-text-field label="*Security Answer 1" clearable
                                              placeholder="giants"
                                              required maxlength="200"
                                              v-model="reg_form.answer1"
                                              :rules="[() => !!reg_form.answer1 || 'This field is required']">
                                </v-text-field>
                                <v-combobox label="*Security Question 2" :items="sec_questions" allow-overflow clearable
                                            placeholder="What was your high school mascot?"
                                            required maxlength="200"
                                            v-model="reg_form.question2"
                                            :rules="[field_rules.required, field_rules.sec_match]">
                                    <template v-slot:item="{ index, item }">

                                        <div class="pa-1 mb-1" style="border-bottom: 0.5px solid #c1c1c1; width: 100%;">
                                            {{item}}
                                        </div>


                                    </template>
                                </v-combobox>
                                <v-text-field label="*Security Answer 2" clearable
                                              placeholder="tiger"
                                              required maxlength="200"
                                              v-model="reg_form.answer2"
                                              :rules="[() => !!reg_form.answer2 || 'This field is required']">
                                </v-text-field>
                            </v-form>

                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 3" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_reg" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 5" :disabled="!security_step_form_valid" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowRight
                                    </v-icon>
                                </v-btn>
                            </v-col>


                        </v-row>




                    </v-stepper-content>

                    <!--CJIS Step-->
                    <v-stepper-content step="5" class="scroll_card">

                        <v-card class="mb-12" light flat
                                height="auto">
                            <div class="mb-2">
                                Please complete the following additional items.
                            </div>

                            <v-form ref="finish_step_form" v-model="finish_step_form_valid" class="ml-lg-2 mt-4">

                                <v-checkbox v-model="reg_form.notsworn" color="primary">
                                    <template v-slot:label>
                                        <div>
                                            I am NOT A sworn Peace Officer of the Reno Police Department
                                        </div>
                                    </template>
                                </v-checkbox>

                                <v-expand-transition>
                                    <div v-if="reg_form.notsworn" class="ml-5">

                                        <div class="mb-2">
                                            Based on your previous selection additonal information is required.
                                        </div>

                                        <v-text-field label="*Agency" maxlength="150"
                                                      placeholder="Sparks Police Department"
                                                      v-model="reg_form.organization" :rules="[field_rules.required_notsworn]">
                                        </v-text-field>

                                        <v-text-field label="*Division" maxlength="150"
                                                      placeholder="Records"
                                                      v-model="reg_form.division" :rules="[field_rules.required_notsworn]">
                                        </v-text-field>

                                        <v-text-field label="*Your Supervisor" maxlength="150"
                                                      placeholder="Sgt. Doe"
                                                      v-model="reg_form.supervisor" :rules="[field_rules.required_notsworn]">
                                        </v-text-field>

                                        <v-text-field label="*Contact Phone Number" @input="formatPhoneNumber" maxlength="14"
                                                      placeholder="(775) 765-9895"
                                                      v-model="reg_form.contact_phone_number" :rules="[field_rules.required_notsworn, field_rules.phone]">
                                        </v-text-field>

                                    </div>
                                </v-expand-transition>

                                <v-checkbox v-model="reg_form.cjis_check" color="primary" :rules="[() => reg_form.cjis_check]">
                                    <template v-slot:label>
                                        <div>
                                            *I have completed RPD security CJIS security awareness training and will comply with this policy.
                                        </div>
                                    </template>
                                </v-checkbox>


                            </v-form>



                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 4" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>


                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_reg" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 6" :disabled="!finish_step_form_valid" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowRight
                                    </v-icon>
                                </v-btn>
                            </v-col>

                        </v-row>




                    </v-stepper-content>

                    <!--OTP Step-->
                    <v-stepper-content step="6" class="scroll_card">

                        <v-card class="mb-12" light flat
                                height="auto">
                            <div class="mb-2">
                                To confirm the email address you provided during registration, please click the "<strong>Send Passcode</strong>" button below to have a one-time passcode sent to your email.
                                <br />
                                <br />
                                <strong>The passcode will be viable for 10 minutes.</strong> When you receive the passcode email, please follow the instructions therein. If you do not receive a passcode email, please check your spam folder.

                                If ten minutes elapse and you are unable to complete the passcode verification step, you will be provided an opportunity to generate a new passcode email.

                            </div>



                            <v-expand-transition>
                                <div ref="otp_step_form" class="mt-4">



                                    <div>
                                        <v-row class="mt-4">
                                            <v-col cols="12" sm="12" md="2" class="mr-2 mt-3">
                                                <v-btn class=""
                                                       outlined
                                                       color="primary" @click="send_otp" :disabled="send_otp_disabled">
                                                    <v-icon left>
                                                        $send
                                                    </v-icon>
                                                    {{timer_field}}
                                                </v-btn>
                                            </v-col>

                                            <v-col cols="12" sm="12" md="4">
                                                <v-otp-input :length="otp_form.otp_length" type="number" v-model="otp_form.otp_value" :disabled="otp_disabled" @finish="check_otp" v-if="otp_form.otp_sent">

                                                </v-otp-input>

                                            </v-col>
                                            <v-spacer></v-spacer>
                                        </v-row>

                                    </div>





                                </div>
                            </v-expand-transition>

                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 5" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>


                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_reg" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 7" :disabled="!otp_step_form_valid" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowRight
                                    </v-icon>
                                </v-btn>
                            </v-col>

                        </v-row>




                    </v-stepper-content>

                    <!--Password Step-->
                    <v-stepper-content step="7" class="scroll_card">

                        <v-card class="mb-12" light flat
                                height="auto">
                            <div class="mb-2">
                                Now its time to create your *required CJIS compliant password and complete your registration. This password must be at least 8 characters in length and contain at least (1 Upper Case Letter) + (1 Lower Case Letter) + (1 Number) and (1 Symbol !@#$&*%^).

                                <br />
                                <br />
                                Note: It is recommended that you do not use passwords associated with other systems, share with others or write your password down.

                                <br />
                                <br />

                                The email provided during registration will serve as your username to login.

                            </div>

                            <v-form ref="password_step_form" v-model="password_step_form_valid" class="mt-4">
                                <v-text-field label="*Password" maxlength="200"
                                              placeholder="********"
                                              :type="password_show ? 'text' : 'password'"
                                              :append-icon="password_show ? '$vuetify.icons.eye' : '$vuetify.icons.eyeoff'"
                                              @click:append="password_show = !password_show"
                                              required
                                              v-model="reg_form.password"
                                              :rules="[field_rules.required, field_rules.min, field_rules.uppercase, field_rules.lowercase, field_rules.special, field_rules.number]">
                                </v-text-field>
                                <v-text-field label="*Confirm Password" maxlength="200"
                                              placeholder="********"
                                              required
                                              :type="password_confirm_show ? 'text' : 'password'"
                                              :append-icon="password_confirm_show ? '$vuetify.icons.eye' : '$vuetify.icons.eyeoff'"
                                              @click:append="password_confirm_show = !password_confirm_show"
                                              v-model="reg_form.password_confirm"
                                              :rules="[field_rules.required, field_rules.min, field_rules.uppercase, field_rules.lowercase, field_rules.special, field_rules.number, field_rules.match]">
                                </v-text-field>

                            </v-form>

                        </v-card>

                        <v-divider light></v-divider>
                        <v-row justify="center" align="center" dense class="mt-4">

                            <v-col md="1" class="text-center">
                                <v-btn light color="primary" @click="step = 6" class="pa-0">
                                    <v-icon>
                                        $vuetify.icons.arrowLeft
                                    </v-icon>

                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light text color="red" @click="cancel_reg" class="pa-0">
                                    Cancel
                                </v-btn>
                            </v-col>

                            <v-col md="1" class="text-center">
                                <v-btn light color="green" @click="submit_reg" :disabled="!password_step_form_valid" class="pa-0">
                                    <v-icon>
                                        $checkboxMarkedOutline
                                    </v-icon>
                                </v-btn>
                            </v-col>


                        </v-row>






                    </v-stepper-content>

                </v-stepper-items>
                <v-overlay absolute :value="overlay_loading">
                    <v-progress-circular indeterminate :size="80" :width="7"
                                         color="primary"></v-progress-circular>
                </v-overlay>
            </v-stepper>

        </Transition>
    </div>
</template>


<style lang="scss" scoped>

    @media only screen and (max-height: 800px) {

        .scroll_card {
            overflow-y: auto;
            max-height: 80vh;
            overflow-x: hidden;
        }
    }

    /*@media only screen and (max-height: 680px) {

        .scroll_card {
            overflow-y: auto;
            max-height: 80vh;
            overflow-x: hidden;
        }
    }*/

    </style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    export default {
        name: "reg",
        mixins: [bus_common],
        data() {
            return {
                step: 1,
                reg_form: {
                    regeventId: 0,
                    email: "",
                    fname: "",
                    lname: "",
                    badge: "",
                    question1: "",
                    answer1: "",
                    question2: "",
                    answer2: "",
                    notsworn: false,
                    organization: "",
                    division: "",
                    supervisor: "",
                    cjis_check: false,
                    password: "",
                    password_confirm: "",
                    contact_phone_number: ""
                },
                detail_step_form_valid: false,
                app_list: [

                ],
                selected_apps: [],
                selected_apps_preview_count: 10,
                security_step_form_valid: false,
                finish_step_form_valid: false,
                overlay_loading: false,
                otp_step_form_valid: false,
                otp_form: {
                    otp_sent: false,
                    otp_value: "",
                    otp_length: 9,
                },
                otp_disabled: false,
                send_otp_disabled: false,
                timer: null,
                timer_text: "",
                password_step_form_valid: false,
                field_rules: {
                    required: value => !!value || 'This field is required',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    max25: v => v.length <= 25 || 'Max 25 characters',
                    match: v => v == this.reg_form.password || 'The passwords you entered do not match',
                    uppercase: value => {
                        const pattern = /.*[A-Z].*/
                        return pattern.test(value) || '1 uppercase letter required'
                    },
                    lowercase: value => {
                        const pattern = /.*[a-z].*/
                        return pattern.test(value) || '1 lowercase letter required'
                    },
                    special: value => {
                        const pattern = /.*[!@#$&*%^].*/
                        return pattern.test(value) || '1 special (!@#$&*%^) character required'
                    },
                    number: value => {
                        const pattern = /.*[0-9].*/
                        return pattern.test(value) || '1 number required'
                    },
                    email: value => {
                        const pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                        return pattern.test(value) || 'Please provide a valid email address'
                    },
                    required_notsworn: value => {
                        const notsworn = this.reg_form.notsworn
                        return !!value && notsworn || 'This field is required'
                    },
                    min_phone: v => v.length >= 14 || 'Please enter a complete phone number',
                    phone: value => {
                        const pattern = /^\d{3}(-|\s)\d{3}(-|\s)\d{4}$|^\d{10}$|^1\s\d{3}(-|\s)\d{3}(-|\s)\d{4}$|^(1\s?)?\(\d{3}\)(\s|\-)?\d{3}\-\d{4}$/;
                        return pattern.test(value) || 'Please provide a valid phone number'
                    },
                    sec_match: v => v != this.reg_form.question1 || 'The same security question cannot be re-used.',
                },
                password_show: false,
                password_confirm_show: false,
                sec_questions: [
                    "In what city did you meet your spouse/significant other?",
                    "In what city do you want to retire?",
                    "In what city does your nearest sibling live?",
                    "In what city or town did your mother and father meet?",
                    "In what city or town was your first job?",
                    "What is the country of your ultimate dream vacation?",
                    "What is the first name of the person that you first kissed?",
                    "What is the middle name of your youngest child?",
                    "What is the name of a college you applied to but didn't attend?",
                    "What is the name of the place your wedding reception was held?",
                    "What is the name of your favorite childhood friend?",
                    "What is the name of your favorite childhood teacher?",
                    "What is your maternal grandmother's maiden name?",
                    "What is your oldest sibling's middle name?",
                    "What month and day is your anniversary? (e.g., January 2)",
                    "What school did you attend for sixth grade?",
                    "What street did you live on in third grade?",
                    "What was the first concert you attended?",
                    "What was the make and model of your first car? (e.g., Subaru Legacy)",
                    "What was the name of your elementary / primary school?",
                    "What was the name of your first stuffed animal?",
                    "What was your childhood home phone number including area code? (e.g., 000-000-0000)",
                    "What was your childhood nickname?",
                    "What was your favorite place to visit as a child?",
                    "Where were you when you had your first kiss?",
                    "Who was your childhood hero?"
                ]

            }
        },

        methods: {
            close_reg() {
                //Clear any paramters
                let query = Object.assign({}, this.$route.query);
                delete query.regeventId;
                this.$router.replace({ query }).catch(() => { });
                this.$emit("cancel_reg")
            },
            cancel_reg() {
                let callback = () => { this.close_reg(); }
                this.swall_diag("Cancel Registration?", "Are you sure you would like to cancel your registration? <br> <br> <b> Any progress made during the registration will be lost. <b/>", "question", callback, "Yes", "No", null)


            },
            async submit_reg() {

                this.overlay_loading = true;

                let form = JSON.parse(JSON.stringify(this.reg_form));
                form.apps = this.selected_apps.join("|")
                await this.$axios.post('api/register/submit', form)
                    .then(response => {
                        this.overlay_loading = false;
                        let callback = () => { this.close_reg(); }
                        this.swall_it("Registration Complete!", "RPD Secure System Administrators will approve your access as soon as possible. Watch your email for an access activation notification. <br> <br> <b> You may now safely close this page. <b/>", "success", callback, null);

                    }).catch(error => {
                        if (error.response) {
                            this.overlay_loading = false;
                            this.swall_it("Error!", error.response.data.msg, "error", null, null);
                        }
                    });


            },
            deleteChip(itemNeedToRemove, array) {
                for (let i = 0; i < array.length; i += 1) {
                    if (array[parseInt(i, 10)] === itemNeedToRemove) {
                        array.splice(i, 1);
                    }
                }
            },
            async send_otp() {
                this.overlay_loading = true;

                let form = JSON.parse(JSON.stringify(this.reg_form));
                form.apps = this.selected_apps.join("|")
                await this.$axios.post('api/register/sendcode', form)
                    .then(response => {
                        this.swall_it("Passcode Sent!", "The passcode has been sent to the email provided. ", "success", null, null);
                        this.set_timer(0, 10);
                        this.otp_form.otp_sent = true;
                        this.otp_form.otp_length = response.data;
                    }).catch(error => {
                        if (error.response) {

                            this.swall_it("Error!", error.response.data.msg, "error", null, null);
                        }
                    });

                this.overlay_loading = false;
            },
            async check_otp(rsp) {

                this.overlay_loading = true
                let form = { regeventId: this.reg_form.regeventId, code: rsp }
                await this.$axios.post('api/register/checkcode', form)
                    .then(response => {
                        this.overlay_loading = false;
                        this.swall_it("Correct Passcode!", "The passcode that was entered was correct. Please proceed to the next registration step. ", "success", null, null);
                        this.otp_disabled = true;
                        this.otp_step_form_valid = true;
                        clearInterval(this.timer);
                        this.timer_text = "";
                    }).catch(error => {
                        if (error.response) {
                            this.overlay_loading = false;
                            this.swall_it("Error!", error.response.data.msg, "error", null, null);

                            //If the error we get is that the code is expired, enable send code button
                            let term = "The passcode you entered is too old"
                            if (error.response.data.msg.includes(term)) {
                                this.send_otp_disabled = false;
                            }
                        }
                    });
            },
            set_timer(hrs, mins) {

                clearInterval(this.timer);
                mins = parseInt(mins) + (parseInt(hrs) * 60);
                // Set the date we're counting down to
                var todayDate = new Date();
                var countDownDate = new Date(todayDate.getTime() + mins * 60000);
                this.send_otp_disabled = true;
                // Update the count down every 1 second
                this.timer = setInterval(() => {
                    // Get today's date and time
                    var now = new Date().getTime();
                    // Find the distance between now and the count down date
                    var distance = countDownDate - now;
                    // Time calculations for days, hours, minutes and seconds
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    // Display the result in the element with id="demo"
                    this.timer_text = minutes + "m " + seconds + "s ";
                    // If the count down is finished, write some text
                    if (distance < 0) {
                        clearInterval(this.timer);
                        this.timer_text = "";
                        this.send_otp_disabled = false;
                    }
                }, 1000);
            },
            formatPhoneNumber() {
                var x = this.reg_form.contact_phone_number.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.reg_form.contact_phone_number = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');

            },
            async init() {
                this.overlay_loading = true;
                await this.get_app_list();
                await this.get_init_reg();
                this.overlay_loading = false;
            },
            async get_app_list() {

                await this.$axios.get('api/register/applist')
                    .then(response => {
                        this.app_list = response.data;
                        this.selected_apps = ["CJIS TEST", "Everwatch", "HOTSHEET RPT", "MISSING", "MOG", "NIBRS TEST SWRN"];
                    }).catch(error => {
                        if (error.response) {
                            let callback = () => { this.close_reg(); }
                            this.swall_it("Error!", error.response.data.msg, "error", callback, null);
                        }
                    });
            },
            async get_init_reg() {

                //Check if we have a current regevent to use
                let regeventId = this.$route.query.regeventId;
                if (regeventId != undefined && parseInt(regeventId) != 0) {
                    var form = { regeventId: parseInt(regeventId) }

                    await this.$axios.post('api/register/init2', form)
                        .then(response => {

                            //Copy Reg data
                            this.reg_form.regeventId = response.data.regeventId;
                            this.reg_form.email = response.data.email;
                            this.reg_form.fname = response.data.fname;
                            this.reg_form.lname = response.data.lname;
                            this.reg_form.badge = response.data.badge;
                            this.reg_form.question1 = response.data.question1;
                            this.reg_form.question2 = response.data.question2;
                            this.reg_form.answer1 = response.data.answer1;
                            this.reg_form.answer2 = response.data.answer2;
                            this.reg_form.cjis_check = response.data.cjis_check;
                            this.reg_form.notsworn = response.data.notsworn;
                            this.reg_form.organization = response.data.organization;
                            this.reg_form.supervisor = response.data.supervisor;
                            this.reg_form.division = response.data.division;
                            this.reg_form.contact_phone_number = response.data.contact_phone_number;
                            this.otp_form.otp_length = response.data.otp_length;


                            this.selected_apps = response.data.apps.split("|");

                            //Skip to passcode step
                            this.step = 6;
                            //Disable send code button since we likely have a code 
                            //Enable otp field to enter code
                            this.send_otp_disabled = true;
                            this.otp_form.otp_sent = true;

                        }).catch(error => {
                            if (error.response) {
                                let callback = () => { this.close_reg(); }
                                this.swall_it("Error!", error.response.data.msg, "error", callback, null);
                            }
                        });
                }
                //If not create baby record
                else {
                    await this.$axios.get('api/register/init')
                        .then(response => {
                            this.reg_form.regeventId = response.data;
                        }).catch(error => {
                            if (error.response) {
                                let callback = () => { this.close_reg(); }
                                this.swall_it("Error!", error.response.data.msg, "error", callback, null);
                            }
                        });
                }



            },
            async VerifyStep2() {
                this.overlay_loading = true;
                //After inital reg items, double check the email is good
                var form = { email: this.reg_form.email }
                await this.$axios.post('api/register/checkemail', form)
                    .then(response => {
                        this.step = 3;
                        console.log(response.data)
                    }).catch(error => {
                        if (error.response) {
                            this.swall_it("Error!", error.response.data.msg, "error", null, null);
                        }
                    });
                this.overlay_loading = false;
            },
            async VerifyStep3() {
                //Double check that at least 1 app is selected
                //Optionally the roles can be double checked here

                if (this.selected_apps.length >= 1) {
                    this.step = 4;
                }
                else {
                    var msg = "You must request access to at least one app. Please try again. "
                    this.swall_it("Error!", msg, "error", null, null);
                }
            },
        },
        computed: {
            timer_field() {

                if (this.timer_text != "") {
                    return this.timer_text;
                }

                else {
                    return "Send Passcode";
                }

            }
        },

        mounted() {
            this.init();
        }
    };
</script>
